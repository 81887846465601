import StoreIcon from "@mui/icons-material/Store";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { IGetInvoicesByCompanyResponse } from "../../api";
import { useAuth } from "../../hooks/contexts/AuthContext";
import { useHTTPRequest } from "../../hooks/contexts/HTTPRequestContext";
import { formatDate, formatDateWithoutHours, maskCNPJ } from "../../utils";

export interface IResultCompanies {
  id: string;
  tx_empresa: string;
  tx_cnpj: string;
}
export interface CompanieDTO {
  count: number;
  pages: number;
  result: {
    id: string;
    tx_empresa: string;
    tx_cnpj: string;
  }[];
}

export const ReadInvoiceListPage: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<CompanieDTO | null>(null);
  const [, setLoadingOptionsCompanies] = useState(true);
  const [, setErrorLoading] = useState(false);
  const [invoices, setInvoices] =
    useState<IGetInvoicesByCompanyResponse | null>();
  const httpRequest = useHTTPRequest();
  const initialValues = {
    id: "",
    date_from: "",
    date_to: "",
  };

  const validationSchema = yup.object().shape({
    id: yup.string().required("Você precisa selecionar uma empresa."),
    date_from: yup
      .string()
      // .test(
      //   "validDateFrom",
      //   "Você precisa adicionar uma data início válida.",
      //   (value) => (value ? checkIsDate(value) : false)
      // )
      .required("Você precisa selecionar uma data início."),
    date_to: yup.string().required("Você precisa selecionar uma data fim."),
  });

  const fetchOptionsCompanies = useCallback(async () => {
    try {
      setLoadingOptionsCompanies(true);

      const response = await httpRequest
        .get(`${process.env.REACT_APP_SERVER_URL}/companies?limit=1000`)
        .then((r) => r.json() as CompanieDTO);

      setCompanies(response);

      setErrorLoading(false);
    } catch (error) {
      setErrorLoading(true);
    } finally {
      setLoadingOptionsCompanies(false);
    }
  }, [httpRequest]);

  useEffect(() => {
    if (auth.tokens) {
      fetchOptionsCompanies();
    }
  }, [auth.tokens, fetchOptionsCompanies]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div
          style={{
            display: "flex",
            textAlign: "left",
            flexDirection: "column",
            width: "100%",
            paddingTop: "1.2rem",
          }}
        >
          <Typography
            sx={{ color: "#22232E", fontWeight: "bold", fontSize: "2.4rem" }}
          >
            Consultar faturas
          </Typography>
          <Typography
            sx={{ color: "#3A3A3A", fontSize: "1.2rem", paddingBottom: "1rem" }}
          >
            Para consultar faturas você precisa escolher uma{" "}
            <strong style={{ color: "#1976D2" }}>empresa</strong>,{" "}
            <strong style={{ color: "#1976D2" }}> data início</strong> e{" "}
            <strong style={{ color: "#1976D2" }}>data fim</strong>.
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              try {
                const response = await httpRequest
                  .get(
                    `https://bifrost.flagcard.com.br/rest/invoices?company=${values.id}&from=${values.date_from}&to=${values.date_to}`
                  )
                  .then((r) => r.json() as IGetInvoicesByCompanyResponse);
                setInvoices(response);
              } catch (error) {
                console.log("error");
              }
            }}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              handleBlur,
              touched,
              errors,
              isSubmitting,
              resetForm,
              isValid,
              dirty,
            }) => {
              return (
                <Form>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      backgroundColor: "white",
                      padding: "2.4rem",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "5px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Box sx={{ mr: "1.2rem" }}>
                        {companies && companies.result.length > 0 ? (
                          <>
                            <Box
                              sx={{ display: "flex", alignItems: "flex-end" }}
                            >
                              <StoreIcon
                                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                              />
                              <Autocomplete
                                id="id"
                                renderOption={(props, option) => {
                                  return (
                                    <li {...props} key={option.id}>
                                      {`${option.tx_empresa}`}
                                    </li>
                                  );
                                }}
                                getOptionLabel={(option) => option.tx_empresa}
                                options={companies.result}
                                sx={{ width: 300 }}
                                onChange={(event, value) => {
                                  setFieldValue("id", value?.id);
                                }}
                                onOpen={handleBlur}
                                includeInputInList
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    onChange={handleChange}
                                    value={values?.id}
                                    label="Empresas"
                                  />
                                )}
                              />
                            </Box>
                            {touched.id && errors.id && (
                              <div
                                style={{
                                  color: "#D91F05",
                                  fontSize: "0.8rem",
                                  textAlign: "center",
                                }}
                              >
                                {errors.id}
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </Box>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <input
                          type="date"
                          id="date_from"
                          name="date_from"
                          placeholder="Data de início"
                          style={{
                            backgroundColor: "white",
                            color: "#757575",
                            border: "1px solid #DADADA",
                            outlineColor: "#1976D2",
                            borderRadius: "3px",
                            padding: "0.8rem",
                            fontSize: "1.2rem",
                          }}
                          onChange={(e: { target: { value: string } }) =>
                            setFieldValue("date_from", e.target.value)
                          }
                        />
                        {touched.date_from && errors.date_from && (
                          <div
                            style={{
                              color: "#D91F05",
                              fontSize: "0.8rem",
                            }}
                          >
                            {errors.date_from}
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "0 1.2rem",
                        }}
                      >
                        <Typography
                          sx={{ color: "#3A3A3A", fontWeight: "bold" }}
                        >
                          até
                        </Typography>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <input
                          type="date"
                          id="date_to"
                          name="date_to"
                          placeholder="Data de fim"
                          style={{
                            backgroundColor: "white",
                            color: "#757575",
                            border: "1px solid #DADADA",
                            outlineColor: "#1976D2",
                            borderRadius: "3px",
                            padding: "0.8rem",
                            fontSize: "1.2rem",
                          }}
                          onChange={(e: { target: { value: string } }) =>
                            setFieldValue("date_to", e.target.value)
                          }
                        />
                        {touched.date_to && errors.date_to && (
                          <div style={{ color: "#D91F05", fontSize: "0.8rem" }}>
                            {errors.date_to}
                          </div>
                        )}
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <Button
                        variant="outlined"
                        // type="submit"
                        // onClick={() => {
                        //   resetForm({
                        //     values: { id: "", date_from: "", date_to: "" },
                        //   });
                        // }}

                        type="reset"
                        style={{
                          padding: "0.8rem 1rem ",
                          marginLeft: "1.6rem",
                          marginRight: "1.6rem",
                          border: "none",
                        }}
                      >
                        Limpar
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={!isValid || !dirty || isSubmitting}
                        style={{ padding: "0.8rem 1rem " }}
                        startIcon={
                          isSubmitting && (
                            <CircularProgress
                              color="inherit"
                              style={{ width: "24px", height: "24px" }}
                            />
                          )
                        }
                      >
                        Consultar
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        <div style={{ width: "100%", marginTop: "2.4rem" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Empresa</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>CNPJ</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Descrição
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Plano</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Percentual
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Data de criação
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Data ínicio
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Data fim</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Consultar detalhe
                  </TableCell>
                </TableRow>
              </TableHead>

              {invoices &&
                invoices.content?.length > 0 &&
                invoices.content.map((item) => {
                  return (
                    <TableBody key={item.id}>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{item.Company.tx_empresa}</TableCell>
                        <TableCell>{maskCNPJ(item.Company.tx_cnpj)}</TableCell>
                        <TableCell>
                          {item.description ? item.description : "-"}
                        </TableCell>
                        <TableCell>{item.Company.cs_plano}</TableCell>
                        <TableCell>{item.Company.nb_percentual}%</TableCell>
                        <TableCell>{formatDate(item.created_at)}</TableCell>
                        <TableCell>
                          {formatDateWithoutHours(item.date_from)}
                        </TableCell>
                        <TableCell>
                          {formatDateWithoutHours(item.date_to)}
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={async () => {
                              try {
                                navigate(`/detalhes-fatura/${item.id}`);
                              } catch (error) {
                                console.log("error");
                              }
                            }}
                            type="submit"
                            variant="contained"
                            style={{
                              padding: "0.8rem 1rem ",
                              textTransform: "none",
                            }}
                          >
                            Ver detalhes
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
            </Table>
            {invoices && invoices.content?.length <= 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2rem 2rem",
                }}
              >
                <img src="void.svg" alt="nada encontrado" width={320} />
                <span
                  style={{
                    fontSize: "1.4rem",
                    color: "#17181E",
                    fontWeight: "bold",
                    padding: "1.2rem 1rem 0rem ",
                  }}
                >
                  Não encontramos nenhuma fatura na data selecionada!
                </span>
                <p style={{ color: "#3A3A3A", fontSize: "1rem" }}>
                  Tente fazer uma nova consulta
                </p>
              </div>
            ) : !invoices ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2rem 2rem",
                }}
              >
                <img src="search.svg" alt="nada buscado" width={320} />
                <span
                  style={{
                    fontSize: "1.4rem",
                    color: "#17181E",
                    fontWeight: "bold",
                    padding: "1.2rem 1rem 0rem ",
                  }}
                >
                  Você ainda não fez a consulta!
                </span>
                <p style={{ color: "#3A3A3A", fontSize: "1rem" }}>
                  Busque faturas apenas com nome da empresa, data início e data
                  fim.
                </p>
              </div>
            ) : (
              <></>
            )}
          </TableContainer>
        </div>
      </LocalizationProvider>
    </>
  );
};
