import { Autocomplete, Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';

import { TransactionDto } from "../../model/transaction.dto";
import { formatDate, formatLiter, formatMileage, formatMoney, formatPlate } from "../../utils";
import { ReactElement, useCallback, useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { useHTTPRequest } from "../../hooks/contexts/HTTPRequestContext";
import { DriverDto } from "../../model/driver.dto";
import { Page } from "../../model/page";
import { AiOutlineUndo } from 'react-icons/ai';

import debounce from 'lodash.debounce';
import { Products } from "./domain/products";


interface DataLineProps {
    name: string;
    value: string | number;
    EditComponent?: ReactElement | boolean;
    transform?: (value: string) => string
}

const DataLine: React.FC<DataLineProps> = ({ name, value, transform, EditComponent }) => {
    const val = `${(transform ? transform(typeof value === 'string' ? value : `${value}`) : value) || ''}`;
    return (
        <div className="flex px-3 py-1 my-1 text-sm justify-start items-start text-justify border-b-2">
            <div className="flex flex-1 font-bold mr-3 uppercase h-full items-center justify-start">
                {name}:
            </div>
            <div className="flex flex-1 font-light h-full">
                {EditComponent ?
                    EditComponent :
                    (val && val.length > 1 ? <>{val}</> : <div className="text-red-800">- SEM DADO -</div>)
                }
            </div>
        </div>
    )
}

function parseDriverEntryFromTransaction(transaction: TransactionDto) {
    return { label: `${transaction.txMatriculaMotorista} - ${transaction.txMotorista.split(' ').filter(e => e.length > 2).slice(0, 2).join(' ')}`.replace(/ {2}/g, ' '), id: transaction.txUUIDMotorista }
}

function parseProductEntryFromTransaction(transaction: TransactionDto) {
    return { label: transaction.txProduto, id: transaction.txUUIDProduto }
}

const TransactionListEntry: React.FC<{ transaction: TransactionDto, onEdit?: (data: Record<string, any>) => void }> = ({ transaction, onEdit }) => {
    const snackbar = useSnackbar();
    const [changes, setChanges] = useState({} as Record<string, any>);
    const [query, setQuery] = useState('');
    const [options, setOptions] = useState<{ label: string; id: string }[]>([]);
    const httpRequest = useHTTPRequest();


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const performQuery = useCallback(debounce(setQuery, 300), []);

    const fetchDrivers = useCallback(async () => {
        const response = await httpRequest.get(`${process.env.REACT_APP_SERVER_URL}/companies/${transaction.txUUIDEmpresa}/drivers?limit=50${query ? `&q=${query}` : ''}`)
            .then(r => r.json() as Page<DriverDto>)

        setOptions([
            ...(transaction.txUUIDMotorista ? [parseDriverEntryFromTransaction(transaction)] : [])
            ,
            ...response.result
                .filter(driver => driver.tx_matricula !== transaction.txMatriculaMotorista)
                .map(driver => ({
                    label: `${driver.tx_matricula} - ${driver.tx_motorista.split(' ')
                        .filter(name => name.length > 2)
                        .slice(0, 2)
                        .join(' ')}`.replace(/ {2}/g, ' '), id: driver.id
                }))
        ]
        );
    }, [httpRequest, query, transaction]);

    useEffect(() => {
        if (onEdit) {
            fetchDrivers();
        }
    }, [fetchDrivers, onEdit]);

    return (
        <div className="flex bg-white w-full h-fit flex-row text-slate-900 my-3 px-5 py-5 shadow-md rounded-md" key={transaction.txAutorizacao}>
            <div className="flex flex-1 flex-col space justify-evenly">
                <DataLine name="Atendente" value={transaction.txFuncionario} />
                <DataLine name="Motorista" value={transaction.txMotorista}
                    EditComponent={
                        onEdit &&
                        <Autocomplete
                            className="shadow-sm rounded-md w-full"
                            disablePortal
                            defaultValue={transaction.txMatriculaMotorista ? parseDriverEntryFromTransaction(transaction) : null}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => {
                                setChanges({ ...changes, 'driver_id': value?.id || null })
                            }
                            }
                            options={options}
                            classes={{ listbox: 'text-xs', inputFocused: 'text-xs' }}
                            renderInput={(params) => <TextField
                                onChange={(event) => performQuery(event.target.value)}
                                {...params} label="Motorista" className="text-xs" />}
                        ></Autocomplete>
                    }

                />
                <DataLine name="Empresa" value={transaction.txEmpresa} />
                <DataLine name="Placa" value={transaction.car_plate} transform={formatPlate} />
                <DataLine name="Quilometragem" value={transaction.mileage} transform={formatMileage}

                    EditComponent={
                        onEdit &&
                        <div className="flex flex-1 flex-row items-center">
                            <NumberFormat
                                className="shadow-sm rounded-md w-full"
                                customInput={TextField}
                                defaultValue={transaction.mileage}
                                value={changes['mileage']}
                                suffix={' Km'}
                                type="text"
                                onValueChange={({ value }) => {
                                    setChanges({ ...changes, 'mileage': value });
                                }}
                            />
                            <AiOutlineUndo className="text-2xl ml-5 cursor-pointer"
                                onClick={() => {
                                    setChanges({ ...changes, mileage: transaction.mileage })
                                }}
                            />
                        </div>
                    }
                />
                <DataLine name="Data" value={transaction.dtTrancacao} transform={formatDate}
                    EditComponent={
                        onEdit &&
                        <div className="flex flex-1 flex-row items-center">
                            <DateTimePicker
                                className="shadow-sm rounded-md w-full"
                                calendarClassName="shadow-sm rounded-md"
                                format="dd/MM/yyyy HH:mm:ss"
                                onChange={(e) => {
                                    setChanges({ ...changes, 'date': e.toISOString() });
                                }}
                                value={new Date(changes['date'] || transaction.dtTrancacao)}
                            />
                            <AiOutlineUndo className="text-2xl ml-5 cursor-pointer"
                                onClick={() => {
                                    setChanges({ ...changes, date: transaction.dtTrancacao })
                                }}
                            />
                        </div>
                    }
                />
            </div>
            <div className="flex flex-1 flex-col ml-10 justify-evenly">
                <DataLine name="Produto" value={transaction.txProduto}
                    EditComponent={
                        onEdit &&
                        <Autocomplete
                            className="shadow-sm rounded-md w-full"
                            disablePortal
                            defaultValue={parseProductEntryFromTransaction(transaction)}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => {
                                setChanges({ ...changes, 'product_id': value?.id || null })
                            }
                            }
                            options={
                                Object.entries(Products).map(([name, uuid]) => ({ label: name, id: uuid }))
                            }
                            classes={{ listbox: 'text-xs', inputFocused: 'text-xs' }}
                            renderInput={(params) => <TextField
                                onChange={(event) => performQuery(event.target.value)}
                                {...params} label="Produto" className="text-xs" />}
                        ></Autocomplete>
                    }
                />
                <DataLine name="Preço" value={transaction.nbValorCombustivel} transform={formatMoney} />
                <DataLine name="Quantidade" value={transaction.quantity} transform={formatLiter}
                    EditComponent={
                        onEdit &&
                        <div className="flex flex-1 flex-row items-center">
                            <NumberFormat
                                className="shadow-sm rounded-md w-full"
                                customInput={TextField}
                                defaultValue={transaction.quantity}
                                value={changes['quantity']}
                                suffix={' Lts'}
                                type="text"
                                onValueChange={({ value }) => {
                                    const newTotal = parseFloat((transaction.nbValorCombustivel * parseFloat(value)).toFixed(2));
                                    setChanges({ ...changes, 'quantity': value, 'total': newTotal });
                                }}
                            />
                            <AiOutlineUndo className="text-2xl ml-5 cursor-pointer"
                                onClick={() => {
                                    setChanges({ ...changes, 'total': transaction.total, quantity: transaction.quantity })
                                }}
                            />
                        </div>
                    }
                />
                <DataLine name="Valor" value={transaction.subtotal} transform={formatMoney} />
                <DataLine name="Desconto" value={transaction.discount} transform={formatMoney}
                    EditComponent={
                        onEdit &&
                        <div className="flex flex-1 flex-row items-center">
                            <NumberFormat
                                className="shadow-sm rounded-md w-full"
                                customInput={TextField}
                                defaultValue={transaction.discount}
                                prefix={'R$ '}
                                value={changes['discount']}
                                type="text"
                                onValueChange={({ value }) => {
                                    const newTotal = parseFloat((transaction.total - parseFloat(value)).toFixed(2));
                                    setChanges({ ...changes, 'discount': value, 'total': newTotal });
                                }}
                            />
                            <AiOutlineUndo className="text-2xl ml-5 cursor-pointer"
                                onClick={() => {
                                    setChanges({ ...changes, 'total': transaction.total, discount: transaction.discount })
                                }}
                            />
                        </div>
                    }
                />
                <DataLine name="Total" value={transaction.total} transform={formatMoney}
                    EditComponent={
                        onEdit &&
                        <div className="flex flex-1 flex-row items-center">
                            <NumberFormat
                                className="shadow-sm rounded-md w-full"
                                customInput={TextField}
                                defaultValue={transaction.total}
                                value={changes['total']}
                                prefix={'R$ '}
                                type="text"
                                onValueChange={({ value }) => {
                                    setChanges({ ...changes, 'total': value });
                                }}
                            />
                            <AiOutlineUndo className="text-2xl ml-5 cursor-pointer"
                                onClick={() => {
                                    setChanges({ ...changes, 'total': transaction.total })
                                }}
                            />
                        </div>
                    }
                />
            </div>
            <div className="flex flex-1 ml-10 flex-col justify-center items-center max-w-fit">
                <div className="text-sm font-bold">Autorização</div>
                <div className="text-sm font-mono underline cursor-copy" onClick={() => {
                    navigator.clipboard.writeText(transaction.txAutorizacao);
                    snackbar.enqueueSnackbar('Autorização copiada para a área de transferência', { variant: 'info' })
                }}>{transaction.txAutorizacao}</div>
                <div className="mt-10">
                    {onEdit ?
                        <Button onClick={() => onEdit(changes)}>Salvar</Button>
                        :
                        <Link
                            to={{
                                pathname: `/transactions/${transaction.txAutorizacao}`,
                            }}>
                            <Button>Editar</Button>
                        </Link>

                    }
                </div>
            </div>
        </div >
    )
}

export default TransactionListEntry;