import { AiOutlineLoading3Quarters } from 'react-icons/ai'
const Spinner = () => {
    return (
        <div className="flex flex-row justify-center align-middle items-center absolute w-full h-full bg-slate-200 opacity-70 top-0 left-0 cursor-wait">
            <div className="w-fit h-fit align-middle font-extralight font-mono text-blue-800 text-8xl flex-col">
                <AiOutlineLoading3Quarters className='animate-spin'/>
            </div>

        </div>)
};

export default Spinner;