import { Button, Menu, MenuItem } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { HashRouter, Link, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Router from "./components/Router";
import { routesConfig } from "./config/routes";
import { AuthProvider, useAuth } from "./hooks/contexts/AuthContext";
import { HTTPRequestProvider } from "./hooks/contexts/HTTPRequestContext";
import { SpinnerProvider } from "./hooks/contexts/SpinnerContext";

function App() {
  const authContext = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="App">
      <div className="App-header">
        <div
          className="flex flex-1 flex-col"
          style={{ width: "100%", height: "100vh" }}
        >
          <div
            style={{
              backgroundColor: "#1976d2",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem",
            }}
          >
            <div style={{ display: "flex" }}>
              <img
                src="/flagcard-logo.svg"
                alt="Logotipo da flagcard"
                className="w-fit w-32"
              />
            </div>

            <div style={{ display: "flex" }}>
              {authContext.tokens?.accessToken && (
                <>
                  <Button
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    Fatura
                  </Button>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to={routesConfig.CREATE_INVOICE}
                    >
                      Criar faturas
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to={routesConfig.LIST_INVOICE}
                    >
                      Consultar faturas
                    </MenuItem>
                  </Menu>
                  <Button
                    size="large"
                    color="inherit"
                    onClick={() => authContext.signOut()}
                  >
                    Sair
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="py-3 px-8 flex flex-1 flex-col bg-slate-200 h-full justify-start items-start">
            {location.pathname !== "/" && (
              <div className="flex flex-row justify-start items-center text-slate-800  h-full">
                <BiArrowBack
                  onClick={() => navigate(-1)}
                  className="cursor-pointer"
                />
                <div className="ml-10 font-light text-base">
                  {location.pathname
                    .replace(/^\//, "")
                    .replace(/\//g, " > ")
                    .toUpperCase()}
                </div>
              </div>
            )}
            <Router />
          </div>
        </div>
      </div>
    </div>
  );
}

const WrappedApp = () => {
  return (
    <SpinnerProvider>
      <AuthProvider>
        <SnackbarProvider autoHideDuration={3000}>
          <HTTPRequestProvider>
            <HashRouter>
              <App />
            </HashRouter>
          </HTTPRequestProvider>
        </SnackbarProvider>
      </AuthProvider>
    </SpinnerProvider>
  );
};

export default WrappedApp;
