import { Autocomplete, Box, Button, Paper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useHTTPRequest } from "../../hooks/contexts/HTTPRequestContext";
import { useSpinner } from "../../hooks/contexts/SpinnerContext";
import addMonths from "date-fns/addMonths";
import format from "date-fns/format";
import { useAuth } from "../../hooks/contexts/AuthContext";
import { useNavigate } from "react-router-dom";
export interface IResultCompanies {
  id: string;
  tx_empresa: string;
  tx_cnpj: string;
}
export interface CompanieDTO {
  count: number;
  pages: number;
  result: {
    id: string;
    tx_empresa: string;
    tx_cnpj: string;
  }[];
}

export interface ICreateInvoice {
  company_id: string;
  from: string;
  to: string;
  description: string;
}

export const CreateInvoicesPage: React.FC = () => {
  const spinner = useSpinner();
  const snackbar = useSnackbar();
  const auth = useAuth();
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<CompanieDTO | null>(null);
  const [eventDate, setEventDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const maxDate = format(addMonths(new Date(), 0), "yyyy-MM-dd");
  const [, setLoadingOptionsCompanies] = useState(true);
  const [, setErrorLoading] = useState(false);
  const httpRequest = useHTTPRequest();
  const initialValues = {
    id: "",
    date_from: "",
    date_to: "",
    description: "",
  };

  const validationSchema = yup.object().shape({
    id: yup.string().required("Precisamos que você escolha uma empresa."),
    date_from: yup
      .string()
      .required("Você precisa selecionar uma data de início."),
    date_to: yup.string().required("Você precisa selecionar uma data de fim."),
    description: yup
      .string()
      .max(300, "Sua descrição só pode ter 300 caracteres"),
  });

  const fetchOptionsCompanies = useCallback(async () => {
    try {
      setLoadingOptionsCompanies(true);
      const response = await httpRequest
        .get(`${process.env.REACT_APP_SERVER_URL}/companies?limit=1000`)
        .then((r) => r.json() as CompanieDTO);

      setCompanies(response);

      setErrorLoading(false);
    } catch (error) {
      setErrorLoading(true);
    } finally {
      setLoadingOptionsCompanies(false);
    }
  }, [httpRequest]);

  useEffect(() => {
    if (auth.tokens) {
      fetchOptionsCompanies();
    }
  }, [auth.tokens, fetchOptionsCompanies]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              try {
                spinner.setLoading(true);
                await httpRequest
                  .post(`https://bifrost.flagcard.com.br/rest/invoices`, {
                    company_id: values.id,
                    from: values.date_from,
                    to: values.date_to,
                    description: values.description,
                  })
                  .then((r) => r.json() as ICreateInvoice[]);
                snackbar.enqueueSnackbar("Fatura criada com sucesso!", {
                  variant: "success",
                  persist: false,
                });
                setTimeout(() => {
                  navigate(`/lista-fatura/`);
                }, 2000);
                // actions.resetForm();
              } catch (err) {
                snackbar.enqueueSnackbar(
                  (err as Error).message || "Algo deu errado!",
                  { variant: "error" }
                );
                console.log("error");
              } finally {
                setTimeout(() => {
                  spinner.setLoading(false);
                }, 500);
              }
            }}
          >
            {({
              values,
              dirty,
              isValid,
              isSubmitting,
              setFieldValue,
              errors,
              handleChange,
              handleBlur,
              touched,
            }) => {
              return (
                <Form>
                  <Paper sx={{ p: "2rem", width: 600 }}>
                    <Typography
                      style={{
                        fontSize: "2.4rem",
                        fontWeight: "bold",
                        color: "#3A3A3A",
                        textAlign: "left",
                      }}
                    >
                      Criar fatura
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "1rem",
                        color: "#3A3A3A",
                        textAlign: "left",
                      }}
                    >
                      Para criar uma fatura você precisa selecionar uma{" "}
                      <strong style={{ color: "#1976D2" }}>empresa</strong>,{" "}
                      <strong style={{ color: "#1976D2" }}>descrição</strong>,{" "}
                      <strong style={{ color: "#1976D2" }}> data início</strong>{" "}
                      e <strong style={{ color: "#1976D2" }}>data fim</strong>.
                    </Typography>

                    <Box sx={{ mt: "1.2rem", width: "100%" }}>
                      {companies && companies.result.length > 0 ? (
                        <>
                          <Autocomplete
                            fullWidth
                            style={{ width: "100%" }}
                            id="id"
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.tx_empresa}
                                </li>
                              );
                            }}
                            getOptionLabel={(option) => option.tx_empresa}
                            options={companies.result}
                            sx={{ width: 300 }}
                            onChange={(event, value) => {
                              setFieldValue("id", value?.id);
                            }}
                            onOpen={handleBlur}
                            includeInputInList
                            // value={values.id}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onChange={handleChange}
                                value={values?.id}
                                placeholder="Selecione uma empresa"
                                label="Empresa"
                              />
                            )}
                          />
                          {touched.id && errors.id && (
                            <div
                              style={{
                                color: "#D91F05",
                                fontSize: "0.8rem",
                                textAlign: "center",
                              }}
                            >
                              {errors.id}
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <TextField
                        id="description"
                        label="Descrição"
                        placeholder="Descrição da fatura com até 300 caracteres"
                        multiline
                        style={{ width: "100%", marginTop: "1.2rem" }}
                        onChange={(e: { target: { value: string } }) =>
                          setFieldValue("description", e.target.value)
                        }
                      />
                      {touched.description && errors.description && (
                        <div
                          style={{
                            color: "#D91F05",
                            fontSize: "0.8rem",
                            textAlign: "center",
                          }}
                        >
                          {errors.description}
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingTop: "1.2rem",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <input
                          type="date"
                          id="date_from"
                          name="date_from"
                          placeholder="Data de início"
                          style={{
                            backgroundColor: "white",
                            color: "#757575",
                            border: "1px solid #DADADA",
                            outlineColor: "#1976D2",
                            borderRadius: "3px",
                            padding: "0.8rem",
                            fontSize: "1.2rem",
                          }}
                          onChange={(e: { target: { value: string } }) =>
                            setFieldValue("date_from", e.target.value)
                          }
                        />
                        {touched.date_from && errors.date_from && (
                          <div
                            style={{
                              color: "#D91F05",
                              fontSize: "0.8rem",
                              textAlign: "center",
                            }}
                          >
                            {errors.date_from}
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ color: "#3A3A3A", fontWeight: "bold" }}
                        >
                          até
                        </Typography>
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <input
                          type="date"
                          id="date_to"
                          name="date_to"
                          max={maxDate}
                          placeholder="Data de fim"
                          style={{
                            backgroundColor: "white",
                            color: "#757575",
                            border: "1px solid #DADADA",
                            outlineColor: "#1976D2",
                            borderRadius: "3px",
                            padding: "0.8rem",
                            fontSize: "1.2rem",
                          }}
                          onChange={(e: { target: { value: string } }) =>
                            setFieldValue("date_to", e.target.value)
                          }
                        />
                        {touched.date_to && errors.date_to && (
                          <div
                            style={{
                              color: "#D91F05",
                              fontSize: "0.8rem",
                              textAlign: "center",
                            }}
                          >
                            {errors.date_to}
                          </div>
                        )}
                      </div>
                    </div>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!isValid || !dirty || isSubmitting}
                      style={{
                        width: "100%",
                        marginTop: "1.2rem",
                        padding: "0.8rem",
                        textTransform: "none",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      Criar fatura
                    </Button>
                  </Paper>
                </Form>
              );
            }}
          </Formik>
        </div>
      </LocalizationProvider>
    </>
  );
};
