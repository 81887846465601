import moment from "moment";

export function formatPlate(plate: string) {
  if (plate.length !== 7) {
    return plate;
  }

  return `${plate.substring(0, 3)}-${plate.substring(3)}`;
}

export function formatNumber(nb: string, plates: number = 2) {
  const [whole, decimal] = parseFloat(nb).toFixed(plates).split(".");

  if (whole.match(/\D+/)) {
    return `-.${[...new Array(plates)].map(() => "-").join("")}`;
  }

  return `${whole.split(/(?=(?:\d{3})+(?:\.|$))/g).join(".")},${decimal} `;
}

export function formatDate(dt: string, format: string = "DD/MM/YYYY HH:mm") {
  return moment(dt).format(format);
}

export function formatDateWithoutHours(dt: string, format: string = "DD/MM/YYYY") {
  return moment(dt).format(format);
}

export function formatMileage(nb: string) {
  return `${formatNumber(nb, 2)} Km`;
}

export function formatMoney(nb: string) {
  return `R$ ${formatNumber(nb, 2)} `;
}

export function formatLiter(nb: string) {
  return `${formatNumber(nb, 3)} Lts`;
}

export const maskCNPJ = (CNPJ: string) => {
  let masked = CNPJ.length > 14 ? CNPJ.slice(0, 14) : CNPJ;
  masked = masked.replace(/^(\d{2})(\d)/, "$1.$2");
  masked = masked.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  masked = masked.replace(/\.(\d{3})(\d)/, ".$1/$2");
  masked = masked.replace(/(\d{4})(\d)/, "$1-$2");
  return masked;
};

export const downloadFile = (uriContent: string, name?: string | null) => {
  const link = document.createElement("a");
  link.href = uriContent;
  link.download = name || `Relatório-${moment().format("DD-MM-YYYY")}}`;
  link.click();
};

export function downloadPdf(data: any) {
  var file = new Blob([data], { type: "application/pdf" });
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

export function downloadXlsx(data: any) {
  var file = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

export function checkIsDate(date: string) {
  //TODO 1- Verificar esses overlays; 2- Tratamento de erro para quando botatem só zero; 3- Verificar esse UTC

  const dateValid = moment(date, "DD/MM/YYYY").isValid();

  const useToday = moment();

  const dateBirth = moment(date, "DD/MM/YYYY");

  const validateAge = useToday.diff(dateBirth, "years");

  if (dateValid) {
    return true;
  }
  return false;
}

export function performDateMask(i: string): string {
  const toProcess = i.replace(/\D+/g, "").substring(0, 8);
  if (toProcess.length > 2) {
    if (toProcess.length > 4) {
      return `${toProcess.substring(0, 2)}/${toProcess.substring(
        2,
        4
      )}/${toProcess.substring(4)}`;
    } else {
      return `${toProcess.substring(0, 2)}/${toProcess.substring(2)}`;
    }
  }
  return toProcess;
}