import React, { useCallback, useContext, useState } from "react";
import Spinner from "../../components/Spinner";

interface SpinnerContextData {
    setLoading: (loading: boolean) => void;
    isLoading: boolean;
}

const SpinnerContext = React.createContext({} as SpinnerContextData);


const SpinnerProvider: React.FC = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    const setLoading = useCallback(async (loading: boolean) => {
        setIsLoading(loading);
    }, []);

    return (
        <SpinnerContext.Provider value={{ setLoading, isLoading }}>
            {children}
            {isLoading && <Spinner />}
        </SpinnerContext.Provider>
    )
}

const useSpinner = () => {
    const context = useContext(SpinnerContext);
    if (!context) {
        throw new Error('Auth context is not ready');
    }

    return context;
}

export { useSpinner, SpinnerProvider };