import { Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/contexts/AuthContext";
import { useSpinner } from "../../hooks/contexts/SpinnerContext";

const Login = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const snackbar = useSnackbar();

    const authContext = useAuth();
    const spinner = useSpinner();

    return (
        <div className="flex flex-1 justify-center items-center w-full">
            <div className="flex flex-1 flex-row justify-center items-center relative">
                <div className='bg-blue-50 rounded-lg shadow-sm h-fit w-96 px-5 py-5'>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                            Usuário
                        </label>
                        <TextField
                            onChange={({ target }) => setUsername(target.value)}
                            type="text" placeholder="exemplo@flagcard.com" />
                    </div>

                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Senha
                        </label>
                        <TextField
                            onChange={({ target }) => setPassword(target.value)}
                            type="password" placeholder="********" />
                    </div>

                    <Button disabled={spinner.isLoading} variant="outlined" onClick={async () => {
                        spinner.setLoading(true);
                        const logged = await authContext.signIn(username, password);
                        if (logged) {
                            snackbar.enqueueSnackbar('Login efetuado', {
                                variant: 'success',
                                persist: false,
                            });
                        } else {
                            snackbar.enqueueSnackbar('Usuário ou senha incorretos!', { variant: 'error', persist: false });
                        }
                        spinner.setLoading(false);
                    }} >Login</Button>
                </div>
            </div>
        </div>
    )
}

export default Login;