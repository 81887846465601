import jwtDecode from "jwt-decode";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";

export interface AuthTokens {
  accessToken?: string;
}
export interface AuthContextState {
  tokens?: AuthTokens | null;
}

export interface AuthContextOperations {
  signIn: (username: string, password: string) => Promise<boolean>;
  refresh: (hard?: boolean) => Promise<void>;
  signOut: () => Promise<void>;
}

export type AuthContextData = AuthContextState & AuthContextOperations;
export const AuthContext = React.createContext<AuthContextData>(
  {} as AuthContextData
);

const AuthProvider: React.FC = ({ children }) => {
  const [tokens, setTokens] = useState<AuthTokens | undefined | null>(
    undefined
  );

  const refresh = useCallback(async (hard?: boolean) => {
    const refreshToken = localStorage.getItem("@refreshToken");
    if (refreshToken) {
      const token = jwtDecode(refreshToken) as { exp: number };

      if (!hard && moment(token.exp * 1000) > moment().add(2, "minutes")) {
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("client_id", "ragadast");
      urlencoded.append("grant_type", "refresh_token");
      urlencoded.append("refresh_token", refreshToken);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
      };

      const response = await fetch(
        "https://auth.flagcard.com.br/auth/realms/flagcard/protocol/openid-connect/token",
        requestOptions
      )
        .then((response) => response.json())
        .then(
          (result) => result as { refresh_token: string; access_token: string }
        )
        .catch((error) => {
          console.error("refresh login error: ", error);
          return null;
        });

      setTokens({ accessToken: response?.access_token });
    } else {
      setTokens(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem("@refreshToken");
    setTokens(null);
  }, []);

  const signIn = useCallback(async (username: string, password: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("client_id", "ragadast");
    urlencoded.append("grant_type", "password");
    urlencoded.append("username", username);
    urlencoded.append("password", password);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };

    const response = await fetch(
      "https://auth.flagcard.com.br/auth/realms/flagcard/protocol/openid-connect/token",
      requestOptions
    )
      .then((response) => response.json())
      .then(
        (result) => result as { refresh_token: string; access_token: string }
      )
      .catch((error) => {
        console.error("loggin error: ", error);
        return null;
      });

    setTokens({ accessToken: response?.access_token as string });
    localStorage.setItem("@refreshToken", response?.refresh_token || "");
    return Boolean(response);
  }, []);

  useEffect(() => {
    async function run() {
      await refresh(true);
    }
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ tokens, signIn, refresh, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("Auth context is not ready");
  }

  return context;
};

export { useAuth, AuthProvider };
