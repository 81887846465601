import React, { useContext } from "react";
import { useAuth } from "./AuthContext";

export interface HTTPRequestContextState {
  get: (
    url: string,
    params?: Record<string, any>,
    headers?: Record<string, any>
  ) => Promise<any>;
  getWithArrayBuffer: (
    url: string,
    params?: Record<string, any>,
    headers?: Record<string, any>
  ) => Promise<any>;
  patch: (
    url: string,
    body: any,
    params?: Record<string, any>,
    headers?: Record<string, any>
  ) => Promise<any>;
  put: (
    url: string,
    body: any,
    params?: Record<string, any>,
    headers?: Record<string, any>
  ) => Promise<any>;
  post: (
    url: string,
    body: any,
    params?: Record<string, any>,
    headers?: Record<string, any>
  ) => Promise<any>;
}

const HTTPRequestContext = React.createContext<HTTPRequestContextState>(
  {} as HTTPRequestContextState
);

const HTTPRequestProvider: React.FC = ({ children }) => {
  const authContext = useAuth();

  const get = async (
    url: string,
    params?: Record<string, any>,
    headers?: Record<string, any>
  ): Promise<any> => {
    await authContext.refresh();
    if (!authContext.tokens?.accessToken) {
      await authContext.signOut();
      throw new Error("Sessão de usuário inválida");
    }

    const parsedUrl = new URL(url);

    if (params) {
      Object.keys(params).forEach((key) =>
        parsedUrl.searchParams.append(key, params[key])
      );
    }
    return fetch(url, {
      method: "GET",
      headers: {
        ...headers,
        Authorization: `Bearer ${authContext.tokens.accessToken}`,
      },
    }).then(async (r) => {
      if (!r.ok) {
        const e = await r.json();
        throw new Error(e.message);
      }
      return r;
    });
  };

  const getWithArrayBuffer = async (
    url: string,
    params?: Record<string, any>,
    headers?: Record<string, any>
  ): Promise<any> => {
    await authContext.refresh();
    if (!authContext.tokens?.accessToken) {
      await authContext.signOut();
      throw new Error("Sessão de usuário inválida");
    }

    const parsedUrl = new URL(url);

    if (params) {
      Object.keys(params).forEach((key) =>
        parsedUrl.searchParams.append(key, params[key])
      );
    }
    return fetch(url, {
      method: "GET",
      headers: {
        ...headers,
        Authorization: `Bearer ${authContext.tokens.accessToken}`,
      },
    }).then(function (response) {
      return response.arrayBuffer();
    });
  };

  const patch = async (
    url: string,
    body: any,
    params?: Record<string, any>,
    headers?: Record<string, any>
  ): Promise<any> => {
    await authContext.refresh();
    if (!authContext.tokens?.accessToken) {
      await authContext.signOut();
      throw new Error("Sessão de usuário inválida");
    }

    const parsedUrl = new URL(url);

    if (params) {
      Object.keys(params).forEach((key) =>
        parsedUrl.searchParams.append(key, params[key])
      );
    }
    return fetch(url, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...headers,
        Authorization: `Bearer ${authContext.tokens.accessToken}`,
      },
    });
  };

  const put = async (
    url: string,
    body: any,
    params?: Record<string, any>,
    headers?: Record<string, any>
  ): Promise<any> => {
    await authContext.refresh();
    if (!authContext.tokens?.accessToken) {
      await authContext.signOut();
      throw new Error("Sessão de usuário inválida");
    }

    const parsedUrl = new URL(url);

    if (params) {
      Object.keys(params).forEach((key) =>
        parsedUrl.searchParams.append(key, params[key])
      );
    }
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...headers,
        Authorization: `Bearer ${authContext.tokens.accessToken}`,
      },
    });
  };

  const post = async (
    url: string,
    body: any,
    params?: Record<string, any>,
    headers?: Record<string, any>
  ): Promise<any> => {
    await authContext.refresh();
    if (!authContext.tokens?.accessToken) {
      await authContext.signOut();
      throw new Error("Sessão de usuário inválida");
    }

    const parsedUrl = new URL(url);

    if (params) {
      Object.keys(params).forEach((key) =>
        parsedUrl.searchParams.append(key, params[key])
      );
    }
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...headers,
        Authorization: `Bearer ${authContext.tokens.accessToken}`,
      },
    });
  };

  return (
    <HTTPRequestContext.Provider
      value={{ get, patch, post, getWithArrayBuffer, put }}
    >
      {children}
    </HTTPRequestContext.Provider>
  );
};

const useHTTPRequest = () => {
  const context = useContext(HTTPRequestContext);

  if (!context) {
    throw new Error("HTTPRequestContext is not ready");
  }
  return context;
};

export { useHTTPRequest, HTTPRequestProvider };
