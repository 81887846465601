import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/contexts/AuthContext";
import { useHTTPRequest } from "../../hooks/contexts/HTTPRequestContext";
import { useSpinner } from "../../hooks/contexts/SpinnerContext";
import { TransactionDto } from "../../model/transaction.dto";
import TransactionListEntry from "./TransactionListEntry";

const TransactionEdit: React.FC = () => {
    const { id } = useParams();
    const [transaction, setTransaction] = useState<TransactionDto | null | undefined>(undefined);
    const snackbar = useSnackbar();
    const spinner = useSpinner();
    const auth = useAuth();

    const httpRequest = useHTTPRequest();

    const getTransaction = useCallback(async (id: string) => {
        setTransaction(undefined);
        try {
            spinner.setLoading(true);
            const response = await httpRequest.get(`${process.env.REACT_APP_SERVER_URL}/transactions/${id}`)

                .then(r => r.json() as TransactionDto);
            if (!response) {
                snackbar.enqueueSnackbar('Transação não encontrada', { variant: 'error' });
            } else {
                setTransaction(response);
            }

        } catch (err) {
            snackbar.enqueueSnackbar((err as Error).message || 'Algo deu errado!', { 'variant': 'error' });
        } finally {
            setTimeout(() => {
                spinner.setLoading(false);
            }, 500);
        }
    }, [httpRequest, snackbar, spinner]);

    useEffect(() => {
        if (id && auth.tokens) {
            getTransaction(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.tokens]);


    const updateTransaction = useCallback(async (data) => {
        if (id) {
            try {
                spinner.setLoading(true);
                const transformedData = {
                    ...('mileage' in data ? { mileage: data.mileage } : {}),
                    ...('date' in data ? { dt_transaction: data.date } : {}),
                    ...('quantity' in data ? { quantity_liter: Math.abs(data.quantity) } : {}),
                    ...('total' in data ? { quantity_money: -Math.abs(data.total) } : {}),
                    ...('discount' in data ? { discount: Math.abs(data.discount) } : {}),
                    ...('driver_id' in data ? { driver_id: data.driver_id } : {}),
                    ...('product_id' in data ? { product_id: data.product_id } : {}),
                };
                console.log(transformedData);
                await httpRequest.patch(`${process.env.REACT_APP_SERVER_URL}/transactions/${id}`, transformedData)
                snackbar.enqueueSnackbar('Transação alterada com sucesso!', { 'variant': 'success' });

            } catch (err) {
                snackbar.enqueueSnackbar((err as Error).message || 'Algo deu errado!', { 'variant': 'error' });
            } finally {
                setTimeout(() => {
                    spinner.setLoading(false);
                }, 500);
            }

            getTransaction(id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex flex-auto w-full h-full flex-col">
            {transaction && <TransactionListEntry transaction={transaction}

                onEdit={(data) => {
                    updateTransaction(data);
                }}
            />}
        </div>)
}

export default TransactionEdit;