import { useCallback, useEffect, useState } from "react";

//Import Icons MUI Components
import {
  Button,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//Import Other libs
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

import { IGeDetailsInvoicesResponse } from "../../api";
import { useAuth } from "../../hooks/contexts/AuthContext";
import { useHTTPRequest } from "../../hooks/contexts/HTTPRequestContext";
import { useSpinner } from "../../hooks/contexts/SpinnerContext";
import {
  downloadPdf,
  downloadXlsx,
  formatDateWithoutHours,
  formatMoney,
} from "../../utils";

//Import Icons
import PaidIcon from "@mui/icons-material/Paid";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PendingIcon from "@mui/icons-material/Pending";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";
import FileUploadIcon from "@mui/icons-material/FileUpload";

export interface IResultCompanies {
  id: string;
  tx_empresa: string;
  tx_cnpj: string;
}
export interface CompanieDTO {
  count: number;
  pages: number;
  result: {
    id: string;
    tx_empresa: string;
    tx_cnpj: string;
  }[];
}

const Input = styled("input")({
  display: "none",
});

export const DetailsInvoicePage: React.FC = () => {
  const spinner = useSpinner();
  const snackbar = useSnackbar();
  const auth = useAuth();
  const { invoiceId } = useParams();
  const httpRequest = useHTTPRequest();
  const [detailsInvoices, setDetailsInvoices] =
    useState<IGeDetailsInvoicesResponse | null>();

  const [, setLoadingDetailsInvoice] = useState(false);
  const [, setErrorLoadingDetailsInvoice] = useState(true);

  const fetchDetailsInvoice = useCallback(
    async (invoiceId: string) => {
      try {
        setLoadingDetailsInvoice(true);
        const response = await httpRequest
          .get(
            `https://bifrost.flagcard.com.br/rest/invoices/${invoiceId}?type=synthetic`
          )
          .then((r) => r.json() as IGeDetailsInvoicesResponse);
        setDetailsInvoices(response);

        setLoadingDetailsInvoice(false);
      } catch (error) {
        setErrorLoadingDetailsInvoice(true);
      } finally {
        setLoadingDetailsInvoice(false);
      }
    },
    [httpRequest]
  );

  useEffect(() => {
    if (invoiceId && auth.tokens) {
      fetchDetailsInvoice(invoiceId);
    }
  }, [auth.tokens, fetchDetailsInvoice, invoiceId]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        style={{
          display: "flex",
          // justifyContent: "center",
          flexDirection: "column",
          textAlign: "left",
          width: "100%",
          paddingTop: "1.2rem",
          paddingBottom: "1rem",
        }}
      >
        <Typography
          sx={{ color: "#22232E", fontWeight: "bold", fontSize: "2.4rem" }}
        >
          Detalhes da fatura
        </Typography>
        {detailsInvoices && (
          <>
            <Typography sx={{ color: "#3A3A3A", fontSize: "1.2rem" }}>
              Aqui você tem as transações da empresa{" "}
              <strong style={{ color: "#1976D2" }}>
                {detailsInvoices.Company.tx_empresa}{" "}
              </strong>
              no período de{" "}
              <strong style={{ color: "#1976D2" }}>
                {" "}
                {formatDateWithoutHours(detailsInvoices.date_from)}
              </strong>{" "}
              até{" "}
              <strong style={{ color: "#1976D2" }}>
                {formatDateWithoutHours(detailsInvoices.date_to)}
              </strong>
              .
            </Typography>
          </>
        )}
      </div>
      <div style={{ width: "100%", marginTop: "0.8rem" }}>
        <TableContainer component={Paper} sx={{ maxHeight: "70vh" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  Estabelecimento
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Subtotal</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Taxa Flagcard(%)
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Taxa Flagcard(R$)
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Gerar fatura
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Pagar fatura
                </TableCell>
              </TableRow>
            </TableHead>

            {detailsInvoices && detailsInvoices.transactions?.length > 0 ? (
              detailsInvoices.transactions.map((item, key) => {
                return (
                  <TableBody key={item.Store.id}>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{item.Store.tx_estabelecimento}</TableCell>
                      <TableCell>
                        {formatMoney(item.subtotal.toString())}
                      </TableCell>
                      <TableCell>{item.Store.nb_percentual}%</TableCell>
                      <TableCell>
                        {formatMoney(item.flagcardReceipt.toString())}
                      </TableCell>
                      <TableCell>
                        {formatMoney(item.total.toString())}
                      </TableCell>
                      <TableCell>
                        {item.status === "pago" ? (
                          <Chip
                            icon={<CheckIcon />}
                            label="Pago"
                            variant="filled"
                            color="success"
                          />
                        ) : item.status === "nao_pago" ? (
                          <Chip
                            icon={<ErrorOutlineIcon />}
                            label="Não pago"
                            variant="filled"
                            color="error"
                          />
                        ) : (
                          <Chip
                            icon={<PendingIcon />}
                            label="Pago parcial"
                            variant="filled"
                            color="warning"
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Button
                            variant="outlined"
                            startIcon={<PictureAsPdfIcon />}
                            onClick={async () => {
                              try {
                                spinner.setLoading(true);
                                const response =
                                  await httpRequest.getWithArrayBuffer(
                                    `https://bifrost.flagcard.com.br/rest/report/transactions-billing/?store=${
                                      item.Store.id
                                    }&startdate=${new Date(
                                      detailsInvoices.date_from
                                    ).valueOf()}&enddate=${new Date(
                                      detailsInvoices.date_to
                                    ).valueOf()}&open=true&format=pdf&company=${
                                      detailsInvoices.company_id
                                    }`
                                  );

                                downloadPdf(response);
                                snackbar.enqueueSnackbar(
                                  "Redirecionado para download do PDF.",
                                  {
                                    variant: "success",
                                    persist: false,
                                  }
                                );
                              } catch (err) {
                                snackbar.enqueueSnackbar(
                                  (err as Error).message || "Algo deu errado!",
                                  { variant: "error" }
                                );
                                console.log("error");
                              } finally {
                                setTimeout(() => {
                                  spinner.setLoading(false);
                                }, 500);
                              }
                            }}
                          >
                            Gerar PDF
                          </Button>
                          <Button
                            variant="outlined"
                            startIcon={<ArticleIcon />}
                            onClick={async () => {
                              try {
                                spinner.setLoading(true);
                                const response =
                                  await httpRequest.getWithArrayBuffer(
                                    `https://bifrost.flagcard.com.br/rest/report/transactions-billing/?store=${
                                      item.Store.id
                                    }&startdate=${new Date(
                                      detailsInvoices.date_from
                                    ).valueOf()}&enddate=${new Date(
                                      detailsInvoices.date_to
                                    ).valueOf()}&open=true&format=xlsx&company=${
                                      detailsInvoices.company_id
                                    }`
                                  );

                                downloadXlsx(response);
                                snackbar.enqueueSnackbar(
                                  "Download do EXCEL feito com sucesso!",
                                  {
                                    variant: "success",
                                    persist: false,
                                  }
                                );
                              } catch (err) {
                                snackbar.enqueueSnackbar(
                                  (err as Error).message || "Algo deu errado!",
                                  { variant: "error" }
                                );
                                console.log("error");
                              } finally {
                                setTimeout(() => {
                                  spinner.setLoading(false);
                                }, 500);
                              }
                            }}
                          >
                            Gerar EXCEL
                          </Button>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        {item.status === "nao_pago" ||
                        item.status === "pago_parcial" ? (
                          <Button
                            onClick={async () => {
                              try {
                                spinner.setLoading(true);
                                await httpRequest.put(
                                  `https://bifrost.flagcard.com.br/rest/invoices/billed`,
                                  {
                                    company_id: detailsInvoices.company_id,
                                    store_id: item.Store.uuid,
                                    invoice_id: invoiceId,
                                  }
                                );
                                window.location.reload();
                                snackbar.enqueueSnackbar(
                                  "Realizando pagamento",
                                  {
                                    variant: "success",
                                  }
                                );
                              } catch (err) {
                                snackbar.enqueueSnackbar(
                                  (err as Error).message || "Algo deu errado!",
                                  { variant: "error" }
                                );
                                console.log("error", err);
                              } finally {
                                setTimeout(() => {
                                  spinner.setLoading(false);
                                }, 500);
                              }
                            }}
                            variant="contained"
                            startIcon={<PaidIcon />}
                            style={{
                              backgroundColor: "#FACB00",
                              color: "#343434",
                            }}
                          >
                            Pagar
                          </Button>
                        ) : (
                          <label htmlFor="contained-button-file">
                            <Input id="contained-button-file" type="file" />
                            <Button
                              variant="contained"
                              component="span"
                              startIcon={<FileUploadIcon />}
                            >
                              COMPROVANTE
                            </Button>
                          </label>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                );
              })
            ) : (
              <>
                <span style={{ fontSize: "1.4rem" }}>
                  Não encontramos nenhuma fatura na data selecionada
                </span>
              </>
            )}
          </Table>
        </TableContainer>
      </div>
    </LocalizationProvider>
  );
};
