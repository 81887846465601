import { useEffect, useState } from 'react';
import { BiSearchAlt2 } from 'react-icons/bi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/contexts/AuthContext';

interface SearchBarProps {
    placeholder?: string;
    defaultValue?: string;
    onSearch?: (text: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ defaultValue, placeholder, onSearch }) => {
    const [searchParams] = useSearchParams();
    const [text, setText] = useState(defaultValue || searchParams.get('query') || '');
    const auth = useAuth();
    const navigate = useNavigate();

    const search = () => {
        onSearch && onSearch(text);
        navigate({ pathname: '/', search: `?query=${text}` })
    };

    useEffect(() => {
        if (text && auth.tokens?.accessToken) {
            search();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.tokens?.accessToken]);

    return (
        <div className="pt-2 relative mx-auto text-gray-600">
            <input className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
                type="search" name="search-transaction" placeholder={placeholder || 'Search'} onChange={(e) => setText(e.target.value)}
                defaultValue={text}
                onKeyUp={(evt) => {
                    if (evt.code === 'Enter') {
                        search();
                    }
                }}
            />
            <button type="submit" className="absolute right-0 top-0 mt-5 mr-4"
                onClick={() => search()}>
                <BiSearchAlt2 />
            </button>
        </div>)
}

export default SearchBar;