import { Route, Routes } from "react-router-dom"
import { routesConfig } from "../../config/routes";
import { useAuth } from "../../hooks/contexts/AuthContext"
import { CreateInvoicesPage } from "../../pages/CreateInvoices";
import { DetailsInvoicePage } from "../../pages/DetailsInvoice";
import Login from "../../pages/Login"
import { ReadInvoiceListPage } from "../../pages/ReadInvoiceList";
import Transaction from "../../pages/Transaction";
import TransactionEdit from "../../pages/Transaction/TransactionEdit";

const Router = () => {

    const authContext = useAuth();

    if (authContext.tokens === null) {
        return <Login />;
    }

    return (
      <Routes>
        <Route path="/" element={<Transaction />} />
        <Route path="/transactions/:id" element={<TransactionEdit />} />
        <Route
          path={routesConfig.CREATE_INVOICE}
          element={<CreateInvoicesPage />}
        />
        <Route
          path={routesConfig.LIST_INVOICE}
          element={<ReadInvoiceListPage />}
        />
        <Route
          path={"/detalhes-fatura/:invoiceId"}
          element={<DetailsInvoicePage />}
        />
      </Routes>
    );
}

export default Router;